import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import 'vuex'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:"/user/",
    name:"user",
    component: ()=> import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      //check if the user is logged in
      let uid = localStorage.getItem('uid')
      let isAuthenticated = localStorage.getItem('isAuthenticated')
      if(uid !=null && isAuthenticated == "true"){
        next()
      }else{
        router.push(from)
      }
    },
    children:[
      {
        path:"home",
        name:"home",
        component: ()=> import('../views/HomeViews/Home.vue'),
      },
      {
        path:"posts",
        name:"posts",
        component: ()=> import('../views/HomeViews/Posts.vue'),
      },
      {
      path:"coupons",
        name:"coupons",
        component: ()=>import('../views/HomeViews/Coupons')
      },
      {
        path:"profile",
        name:'profile',
        component: ()=> import('../views/HomeViews/Profile')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
