import firebase from "firebase/compat";

const firebaseConfig = {
    apiKey: "AIzaSyBbdvXi5Ph87klc-vBKhsbpcuHHI_-E2xQ",
    authDomain: "couponkeep-f009d.firebaseapp.com",
    databaseURL: "https://couponkeep-f009d-default-rtdb.firebaseio.com",
    projectId: "couponkeep-f009d",
    storageBucket: "couponkeep-f009d.appspot.com",
    messagingSenderId: "286576371809",
    appId: "1:286576371809:web:3e672de4067fbd2839a220",
    measurementId: "G-B9N6SMPEDY"
};

export const app = firebase.initializeApp(firebaseConfig)
export const auth = app.auth()
export const db = app.firestore()
export const storage = app.storage()
