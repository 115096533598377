import {db} from './firebaseModule'

const state = {
    posts: [],
    Comments:[],
}
const mutations = {
    addPosts:(state, post)=> {
        let exists = false
        for (let p in state.posts) {
            if (state.posts[p].id===post.id) {
                exists = true
            }
        }
        if(exists===false){
            state.posts.push(post)
            // localStorage.setItem("Posts",JSON.stringify({"Posts":Posts}))
        }
    },
    addComment:(state,comment)=>{
        state.Comments.unshift(comment)
    },
    clearComments:(state)=>(state.Comments = []),
}
const actions = {
    async loadPosts({commit}){
        let postsQuery = (await db.collection('Posts').limit(10).orderBy('pubDate',"asc").get()).docs
        postsQuery.forEach(async (doc)=>{
            let writer = await db.collection("Accounts").doc(doc.data().writer).get()
            let post = doc.data()
            post.writer = writer.data()
            commit('addPosts',post)
        })
    },
    async getMorePosts({commit},lastPost=null){
        let postQuery = (await db.collection('Posts').limit(10).orderBy('pubDate',"asc").startAfter(lastPost).get()).docs
        postQuery.forEach(async (doc)=>{
            let writer = await db.collection("Accounts").doc(doc.data().writer).get()
            let post = doc.data()
            post.writer = writer.data()
            commit('addPosts',post)
        })
    },
    async loadComments({commit},postID){
        commit('clearComments')
        let commentQuery = (await db.collection('Comments').doc(postID).collection("Comments").limit(10).orderBy('pubDate',"asc").get()).docs
        commentQuery.forEach(async (doc)=>{
            let writer = await db.collection("Accounts").doc(doc.data().writer).get()
            let comment = doc.data()
            comment.writer = writer.data()
            commit('addComment',comment)
        })
    },
    async submitNewComment({commit},comment){
        await db.collection("Comments").doc(comment.post).collection("Comments").add(comment)
        commit('addComment',comment)
    },
    async getUserPosts({commit},uid){
        let postList = []
        const posts = await (await db.collection("Posts").where("writer",'==',uid).get()).docs
        posts.forEach(async (doc)=>{
            let writer = await db.collection("Accounts").doc(doc.data().writer).get()
            let post = doc.data()
            post.writer = writer.data()
            postList.unshift(post)
        });
        commit
        return postList
    }
}
const getters = {
    getPosts:(state)=>state.posts,
    getPostComments: (state)=>state.Comments,
}
const modules = {}
export default {
    state,
    mutations,
    actions,
    getters,
    modules
}