<template>
  <v-app>
    <v-main>
      <header>
        <v-app-bar color="rgba(0,0,0,0.5)" dark fixed elevation="0">
          <v-toolbar-title>
            <v-avatar>
              <img src="../assets/logo.png" alt="John" />
            </v-avatar>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn text href="#home" > Home </v-btn>
          <v-menu left bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on"> Profile </v-btn>
            </template>

            <v-list>
              <v-list-item @click="signInDialog = true">
                <v-list-item-title> Sign In </v-list-item-title>
              </v-list-item>
              <v-list-item @click="signUpDialog = true">
                <v-list-item-title> Sign Up / Register </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn text @click="EULA_Dialog = true" >
            <v-icon>mdi-information-variant</v-icon>
          </v-btn>
        </v-app-bar>
      </header>

      <section id="home" class="banner border-none" border="none">
        <v-row align="center" justify="center">
          <v-col class="text-center" lg="4" sm="12" md="4" id="phone">
            <v-card elevation="0" color="transparent">
              <img src="../assets/app.png" alt="" height="320" />
            </v-card>
          </v-col>
          <v-col class="text-center landing" md="8" lg="8" sm="12">
            <h1 class="text-h4 font-weight-thin mb-4">CouponKeep</h1>
            <h4 class="subheading">Keep calm and KEEP your coupon </h4>
            <div class="btn-group">
              <v-btn
                color="green"
                style="color: #ccc;"
                href="https://play.google.com/store/apps/details?id=com.maidport.couponkeep"
              >
                <v-icon> mdi-android </v-icon>
                Download APK
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </section>
      <div sm class="container">
        <div style="height: 100px"></div>
      </div>
      <div class="container" >
        <div class="row" >
          <div class="col-md-4 col-sm-12 col-lg-4" >
          <v-card elevation="0">
              <img src="../assets/app home.png" alt="" height="290" />
            </v-card>
          </div>
          <div class="col-md-8 col-sm-12 col-lg-8">
          <v-card>
              <v-card-title> Welcome to CouponKeep. </v-card-title>
              <v-card-text>
                This a mobile application designed for coupon users who struggle
                to keep track of their coupons. This application will help you
                keep track of the number of and value of coupons you have and
                also notify you before a paricular coupon expires.
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div
            v-for="f in features"
            :key="f.id"
            class="col-sm-12 col-md-3 col-lg-3"
          >
            <v-card class="text-center deep-orange text-light">
              <v-card-title>
                <v-spacer></v-spacer> {{ f.name }} <v-spacer></v-spacer>
              </v-card-title>

              <v-container class="text-center">
                <v-icon x-large class="text-white"> {{ f.icon }} </v-icon>
              </v-container>

              <v-card-text class="text-white">
                {{ f.description }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>

      <div class="container fluid">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <v-card>
              <v-img src="../assets/svg/subtle-prism.svg" height="320px">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <img src="../assets/app home.png" height="250px" alt="" />
                  </div>
                  <div class="col-lg-12 col-md-12 text-center text-light">
                    <h2>Contact Us</h2>
                  </div>
                </div>
              </v-img>
            </v-card>
          </div>
          <div class="col-md-8">
            <v-form @submit.prevent="submit">
              <v-text-field
                v-model="contactForm.fullName"
                label="Full Name"
                required
                prepend-icon="mdi-account"
              ></v-text-field>

              <v-text-field
                v-model="contactForm.email"
                label="E-mail"
                type="email"
                required
                prepend-icon="mdi-email"
              ></v-text-field>

              <v-textarea
                v-model="contactForm.message"
                clearable
                clear-icon="mdi-close-circle"
                label="Message"
                prepend-icon="mdi-message"
                height="100px"
              ></v-textarea>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-4" @click="signIn">
                  Submit
                </v-btn>
                <v-btn color="warning" reset> Reset </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </div>
      </div>
      <footer class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-12 col-sm 12" id="footer-details">
            <div class="container">
              <div class="row">
                <div class="col-md-4 col-lg-4 col-sm 12">
                  <div class="center">
                    <img src="../assets/logo.png" height="200px" alt="" />
                  </div>
                </div>
                <div class="col-md-8 col-lg-8 col-sm 12 p-1"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-12 col-sm 12 bg-dark p-3" id="f-legal">
            <v-card width="100%" elevation="0" dense color="transparent">
              <v-card-actions>
                <v-avatar>
                  <img src="../assets/logo.png" alt="John" />
                </v-avatar>
                <v-spacer></v-spacer>
                <v-btn text color="white" href="https://www.app-privacy-policy.com/live.php?token=ViZok5iWaLqR8XO3Iw6Tr54W7vDotEF7">Privacy Policy</v-btn>
                <v-btn text color="deep-orange">Developer</v-btn>
              </v-card-actions>
            </v-card>
        </div>
      </footer>

      <div class="text-center">
        <!-- Sign In Dialog -->
        <v-dialog width="320px" v-model="signInDialog">
          <v-card>
            <v-card-title>
              Sign In
              <v-spacer></v-spacer>
              <v-btn icon rounded @click="signInDialog = false">
                <v-icon color="red"> mdi-close-circle </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <form @submit.prevent>
                <v-text-field
                  v-model="signInForm.email"
                  type="email"
                  label="E-mail"
                  required
                  append-icon="mdi-email-outline"
                ></v-text-field>
                <v-text-field
                  v-model="signInForm.password"
                  :append-icon="showSignInPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showSignInPassword ? 'text' : 'password'"
                  name="input-10-2"
                  label="Password"
                  hint="At least 8 characters"
                  class="input-group--focused"
                  @click:append="showSignInPassword = !showSignInPassword"
                ></v-text-field>
                <v-card-actions class="p-0 my-0">
                  <v-spacer></v-spacer>
                  <v-btn x-small text color="warning">
                    Forgot Password ?
                  </v-btn>
                </v-card-actions>
                <hr />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <button
                    class="btn btn-default btn-sm text-warning"
                    type="reset"
                  >
                    RESET
                  </button>
                  <button
                    class="btn btn-sm btn-default text-primary"
                    type="submit"
                    @click="signIn"
                  >
                    SIGN IN
                  </button>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Sign Up Dialog -->
        <v-dialog width="320px" v-model="signUpDialog">
          <v-card>
            <v-card-title>
              Sign Up
              <v-spacer></v-spacer>
              <v-btn icon rounded @click="signUpDialog = false">
                <v-icon color="red"> mdi-close-circle </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <hr />
              <form @submit.prevent="signUp">
                <v-text-field
                  v-model="signUpForm.firstname"
                  type="name"
                  label="First Name"
                  required
                  append-icon="mdi-account-circle-outline"
                ></v-text-field>
                <v-text-field
                  v-model="signUpForm.lastname"
                  type="surname"
                  label="Surname"
                  required
                  append-icon="mdi-account-child-circle"
                ></v-text-field>
                <v-text-field
                  v-model="signUpForm.email"
                  type="email"
                  label="E-mail"
                  required
                  append-icon="mdi-email-outline"
                ></v-text-field>
                <v-text-field
                  v-model="signUpForm.password"
                  :append-icon="showSignInPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showSignInPassword ? 'text' : 'password'"
                  name="input-10-2"
                  label="Password"
                  hint="At least 8 characters"
                  class="input-group--focused"
                  @click:append="showSignInPassword = !showSignInPassword"
                ></v-text-field>
                <v-text-field
                  v-model="signUpForm.passwordConfirm"
                  :append-icon="
                    showSignUpConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :type="showSignUpConfirmPassword ? 'text' : 'password'"
                  name="input-10-2"
                  label="Password"
                  hint="At least 8 characters"
                  class="input-group--focused"
                  @click:append="
                    showSignUpConfirmPassword = !showSignInPassword
                  "
                ></v-text-field>
                Gender:
                <v-card-title class="p-0 my-0">
                  <v-switch
                    v-model="signUpForm.gender"
                    color="var(--main)"
                  ></v-switch>
                  <v-spacer></v-spacer>
                  <v-chip>
                    {{ signUpForm.gender ? "Female" : "Male" }}
                  </v-chip>
                </v-card-title>
                <hr />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <button
                    class="btn btn-default btn-sm text-warning"
                    type="reset"
                  >
                    RESET
                  </button>
                  <button
                    class="btn btn-sm btn-default text-primary"
                    type="submit"
                  >
                    SIGN UP
                  </button>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    signInDialog: false,
    showSignInPassword: false,
    signInForm: {
      email: null,
      password: null,
    },
    signUpDialog: false,
    showSignUpPassword: false,
    showSignUpConfirmPassword: false,
    signUpForm: {
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      handlename: "",
      passwordConfirm: null,
      gender: false,
      photo: "",
      description: "",
      uid: null,
    },
    EULA_Dialog: false,
    contactForm: {
      email: null,
      fullName: null,
      message: null,
    },
    features: [
      {
        id: 0,
        name: "QR Code",
        icon: "mdi-qrcode",
        description: "Save your QR CODE coupon images.",
      },
      {
        id: 1,
        name: "Links",
        icon: "mdi-link-variant",
        description:
          "Link or web  based coupons tend to be hard to remember as links can be long. So here you will be able to save your links and open visit them too.",
      },
      {
        id: 2,
        name: "Pin",
        icon: "mdi-dialpad",
        description:
          "Pin coupons like links can be difficult to remember, and why carry a pen and paper whe you can have them on your phone and a tap away.",
      },
      {
        id: 3,
        name: "Notifications",
        icon: "mdi-bell-badge",
        description:
          "You can also reschedule the notification dates of your coupons.",
      },
    ],
  }),
  methods: {
    ...mapActions(["SIGNIN", "SIGNUP"]),
    signIn() {
      this.SIGNIN(this.signInForm);
    },
    signUp() {
      let newUser = {
        ...this.signUpForm,
      };
      newUser.gender = this.signUpForm.gender ? "Female" : "Male";
      if (this.signUpForm.password === this.signUpForm.passwordConfirm) {
        delete newUser["passwordConfirm"];
        this.SIGNUP(newUser);
        this.signUpDialog = false;
        this.signUpDialog = {};
        this.SIGNIN(newUser);
      } else {
        alert("Password Mismatch...");
      }
    },
    openEULA() {
      this.data.EULA_Dialog = !this.EULA_Dialog;
    },
  },
  computed: {},
};
</script>

<style scoped>
.banner {
  padding: 10px;
  height: 100vh;
  background: url("../assets/svg/subtle-prism.svg") center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.banner h1,
.banner h4 {
  color: #ccc;
}
.banner .landing {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#phone {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile {
  border-radius: 50%;
}
footer {
  width: 100vw;
  background: url("../assets/svg/subtle-prism.svg") center no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #ccc;
}
footer #footer-details {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer #f-legal {
  width: 100vw;
  padding: 10px;
  color: #ccc;
  display: flex;
}
footer .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 425px) {
  #phone {
    width: 100vw;
    height: 80vh;
  }
  .banner .landing {
    height: 20vh;
  }
}
@media screen and (max-width: 960px) {
  #phone {
    height: 70vh;
  }
  .banner .landing {
    height: 30vh;
  }
}
</style>
