import {auth, db} from './firebaseModule';
import router from "../../router"
const state = {
    isAuthenticated: localStorage.isAuthenticated=="true"?true: false,
    uid: localStorage.uid || null,
    user: localStorage.user!==undefined? JSON.parse(localStorage.user) : {
        firstname: null,
        lastname: null,
        email: null,
        gender: null,
        uid: null,
        description: null,
        photo: null
    }
}
const mutations = {
    authenticate: (state, value) => (state.isAuthenticated = value),
    setUser: (state, value) => (state.user = value),
    deAuthenticate: (state) => {
        state.isAuthenticated = false
        state.user = null
    },
}
const actions = {
    async SIGNIN({commit}, user) {
        let u = await auth.signInWithEmailAndPassword(user.email, user.password)
        // var storage = window.localStorage;
        if (u != null) {
            //Get Data from firestore the user list
            let user = await db.collection('Accounts').doc(u.user.uid).get()
            commit('setUser', user.data())
            // Store User Details in localStorage for the duration of the session
            localStorage.setItem('user', JSON.stringify(user.data()) )
            localStorage.setItem('uid',u.user.uid)
            localStorage.setItem('isAuthenticated',true)
            console.log(router.push({path:'/user/home'}))
        }
    },
    async SIGNUP({commit},data){
        await auth.createUserWithEmailAndPassword(data.email, data.password).then(async (value)=>{
            data.uid = value.user.uid
            delete(data['password'])
            await db.collection("Accounts").doc(value.user.uid).set(data)
        });
        commit
        return
    },
    async UpdateUser({commit},newData){
        let result = false;
        await db.collection('Account').doc(newData.uid).update(newData).then(()=>{
            result = true;
        });
        commit;
        return result;
    },
    async SIGNOUT(){
        localStorage.delete('user')
        localStorage.delete('uid')
        localStorage.delete('isAuthenticated')
    }
}
const getters = {
    getUser: () => state.user,
    getUid: () => state.uid,
    getIsAuthenticated: () => state.isAuthenticated
}
const modules = {}

export default {
    state,
    mutations,
    actions,
    getters,
    modules
}