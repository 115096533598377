import {db,storage} from './firebaseModule'

const state = {
    Coupons: [],
    CouponTypes: ["Link", "Pin", "qr_code"]
}
const mutations = {
    AddCoupon: (state, coupon) => {
        let exists = false
        for (let p in state.Coupons) {
            if (state.Coupons[p].id === coupon.id) {
                exists = true
            }
        }
        if (exists === false) {
            state.Coupons.unshift(coupon)
        }
    },
}
const actions = {
    async LoadCoupons({commit}, uid) {
        state.CouponTypes.forEach(async (type) => {
            const linkQuery = (await db.collection('Coupons').doc(uid).collection(type).get()).docs
            linkQuery.forEach((doc) => {
                commit('AddCoupon', doc.data())
            })
        })
    },
    async RescheduleCoupon({commit},load) {
        await db.collection("Coupons").doc(load.uid).collection(load.type).doc(load.id).update({"notificationDate":load.date})
        commit
    },
    async DeleteCoupon({commit}, payload) {
        await db.collection("Coupons").doc(payload.uid).collection(payload.type).doc(payload.id).delete()
        if( payload.type.toLowerCase()=="qr_code" ){
            await storage.refFromURL(payload.mediaLink).delete()
        }
        commit
        this.LoadCoupons(payload.uid)
        return true
    },
    async AddTextCoupon({commit},load){
        commit
        const type = load.type
        const id =  load.id
        const uid = load.owner
        await db.collection("Coupons").doc(uid).collection(type).doc(id).set(load)
    },
    async AddGraphicCoupon({commit},load){
        const uid = load.owner
        let type = load.mediaLink.type.split("/")[1]
        const filename = load.id+"."+type
        const path = `Coupons/${uid}/${filename}`
        // console.log(path)
        await storage.ref(path).put(load.mediaLink).then(async (snapshot)=>{
            let link = await snapshot.ref.getDownloadURL()
            if(link!=null){
                load.mediaLink = link
                await db.collection("coupons").doc(uid).collection(load.type).doc(load.id).set(load)
            }
        })
        commit
    }
}
const getters = {
    getCoupons: (state) => state.Coupons,
    getCouponTypes: (state) => state.CouponTypes,
}
const modules = {}
export default {
    state,
    mutations,
    actions,
    getters,
    modules
}