import Vue from 'vue'
import Vuex from 'vuex'

import Account from './modules/Account'
import Posts from './modules/Posts'
import Coupons from "./modules/Coupons";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Account,
    Posts,
    Coupons,
  }
})
